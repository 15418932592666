'use client'

import { addHost, call } from '@zupr/api'
import { addQueryToUrl, parseUrl } from '@zupr/utils/url'
import { memoize } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const memoizedCall = memoize(call)

export interface UseRequestResponse<Response = any> {
    fetching: boolean
    pause: boolean
    data: Response | null
    retrievedAt: string
    context: {
        all: boolean
        url: string
        original: {
            url?: string
            variables?: Record<string, any>
        }
        variables?: {
            limit?: number
            offset?: number
            ordering?: string
            [key: string]: any
        }
        filters?: Record<string, any>
        filterCount?: number
    }
    error: any
}

export type GetMore = () => void
export type RequestReexecute = () => void
export type RequestMore = (next: string) => void

export interface RequestState<Response = any> {
    url: string
    data?: Response
    retrievedAt: string
    error?: any
}

export interface UseRequestProps<Response = any> {
    url?: string
    variables?: Record<string, any>
    pause?: boolean
    all?: boolean
    onStore?: (state: RequestState<Response>) => void
    state?: any
    method?: 'GET' | 'OPTIONS'
}

export function useUrl({ url, variables }): string {
    return useMemo(() => {
        if (!url) return // waiting for url
        return (
            (variables && addHost(addQueryToUrl(url, variables))) ||
            addHost(url)
        )
    }, [url, variables])
}

/**
 * @deprecated The method should not be used
 */
function useRequestMore({
    setResponse,
    setFetching,
    onStore,
    all,
}: any): RequestMore {
    // when we need all results we keep fetching
    const getMore = useCallback(
        async (next: string) => {
            const more = await memoizedCall({ url: next })

            if (onStore) {
                onStore({
                    url: next,
                    data: more,
                    retrievedAt: new Date().toISOString(),
                })
            }

            setResponse((response) => ({
                ...response,
                data: {
                    ...response.data,
                    next: null,
                    prev: null,
                    results: [...response.data.results, ...more.results],
                },
            }))

            // if we have more results we keep fetching until we get all
            if (all && more.next) {
                return getMore(more.next)
            }

            setFetching(false)
        },
        [all, onStore, setFetching, setResponse]
    )

    return getMore
}

/**
 * @deprecated The method should not be used
 */
const useRequest = function <Response = any>({
    url,
    variables,
    pause,
    all,
    onStore,
    state,
    method = 'GET',
}: UseRequestProps<Response>): [
    UseRequestResponse<Response>,
    RequestReexecute,
    RequestMore
] {
    const [response, setResponse] = useState<RequestState<Response>>()
    const [fetching, setFetching] = useState(!pause)

    const getMore = useRequestMore({ setResponse, setFetching, onStore })

    const fullUrl = useUrl({ url, variables })

    const load = useCallback(
        async (url: string, refetch?: boolean) => {
            setFetching(true)

            try {
                const data = await (refetch
                    ? call({ url, method })
                    : memoizedCall({ url, method }))
                const result = {
                    url,
                    data,
                    retrievedAt: new Date().toISOString(),
                }

                if (onStore) onStore(result)
                setResponse(result)

                if (all && data.next) {
                    return getMore(data.next)
                }

                setFetching(false)
            } catch (error) {
                setResponse({
                    url,
                    error,
                    retrievedAt: new Date().toISOString(),
                })
                setFetching(false)
            }
        },
        [all, getMore, method, onStore]
    )

    useEffect(() => {
        if (pause) return // request is paused
        if (!fullUrl) return // no url yet
        load(fullUrl)
    }, [fullUrl, load, pause])

    const reexecute = useCallback(() => {
        // remove memoized cache
        memoizedCall.cache.clear()
        load(fullUrl)
    }, [fullUrl, load])

    const parsedSettings = useCallback(() => {
        if (!fullUrl) return // waiting for url

        const { url, query } = parseUrl(fullUrl)

        if (query.limit) query.limit = parseInt(query.limit, 10)
        if (query.offset) query.offset = parseInt(query.offset, 10)

        return {
            url,
            variables: query,
        }
    }, [fullUrl])

    const result = useMemo<UseRequestResponse<Response>>(
        () => ({
            fetching,
            pause,
            retrievedAt: response?.retrievedAt,
            error: response?.error,
            data: state || response?.data,
            context: {
                all: !!all,
                ...parsedSettings(),
                original: { url, variables },
            },
        }),
        [all, fetching, parsedSettings, pause, response, state, url, variables]
    )

    return useMemo(
        () => [result, reexecute, getMore],
        [reexecute, result, getMore]
    )
}

/**
 * @deprecated The method should not be used
 */
export const useChunkedRequest = ({ urls, pause, all }) => {
    const [response, setResponse] = useState({
        retrievedAt: '',
        urls: [],
        data: {
            results: [],
        },
    })
    const [fetching, setFetching] = useState(!pause)

    const getMore = useRequestMore({ setResponse, setFetching, all })

    const loadSingle = useCallback(
        async (url) => {
            const data = await memoizedCall(url)

            setResponse((response) => ({
                urls: [...response.urls, url],
                data: {
                    results: [...response.data.results, ...data.results],
                },
                retrievedAt: new Date().toISOString(),
            }))

            if (all && data.next) {
                return getMore(data.next)
            }
        },
        [all, getMore]
    )

    // load multiple urls at once
    const load = useCallback(
        async (urls) => {
            setFetching(true)
            const promises = urls.map(loadSingle)
            await Promise.all(promises)
            setFetching(false)
        },
        [loadSingle]
    )

    // pass memoized list of urls
    useEffect(() => {
        if (pause) return // request is paused
        if (!urls.length) return // no url yet
        load(urls)
    }, [load, pause, urls])

    const reexecute = useCallback(() => {
        load(urls)
    }, [load, urls])

    const result = useMemo(
        () => ({
            fetching,
            pause,
            retrievedAt: response?.retrievedAt,
            data: response?.data,
            context: {
                all: !!all,
                original: { urls },
            },
        }),
        [all, fetching, pause, response, urls]
    )

    return useMemo(() => [result, reexecute], [reexecute, result])
}

export default useRequest
